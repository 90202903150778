import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/SEO"
import { graphql } from "gatsby"

import { Container, Card, Section, Column, Columns, Title } from "bloomer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faUniversalAccess,
  faHandHoldingSeedling,
  faEquals,
} from "../fontAwesome/pro-duotone-svg-icons"
import "@reach/tabs/styles.css"

const BlockContent = require("@sanity/block-content-to-react")

const Workshops = ({ data }) => {
  const serializers = {
    types: {
      block(props) {
        switch (props.node.style) {
          case "h1":
            return <h1 className="tistle card-title i-1">{props.children}</h1>
          case "h2":
            return <h2 className="title card-title is-2">{props.children}</h2>
          case "h3":
            return <h3 className="title card-title is-4">{props.children}</h3>
          case "h4":
            return <h4 className="title card-title is-4">{props.children}</h4>
          case "blockquote":
            return <blockquote>{props.children}</blockquote>
          default:
            return <p>{props.children}</p>
        }
      },
    },
  }

  const shop = data.sanityWorkshops
  return (
    <main>
      <Layout>
        <SEO title="Workshops" />
        <Section>
          <Container>
            <h1 className="title is-1 introTitle" id="main">
              {shop.title}
            </h1>
            <p>
              <BlockContent blocks={shop._rawIntroText} />
            </p>
          </Container>
          <Container className="newSpacing">
            <Columns>
              <Column>
                <Card className="workshopCards">
                  <div class="part-time-box">
                    <div class="top-icon-card">
                      <FontAwesomeIcon
                        icon={faEquals}
                        className="cardIcon faIconColors fa-2x"
                        alt=" Icon pointer right"
                      />
                    </div>
                    <Title tag="h2" className="card-title is-3">
                      {shop.firstWorkshopTitle}
                    </Title>
                    <hr />
                    <div class="best-value">
                      <p class="best-value-price">
                        {shop.firstWorkshopFullPrice}
                      </p>
                      <p class="best-value-sub-line">
                        {shop.firstWorkshopNowSum}
                      </p>
                      <hr />

                      <BlockContent
                        blocks={shop._rawFirstWorkshopDepositExplenation}
                      />
                    </div>

                    <div class="small-list">
                      <BlockContent
                        blocks={shop._rawFirstWorkshopTimes}
                        serializers={serializers}
                      />
                      <BlockContent blocks={shop._rawFirstWorkshopTimes} />
                    </div>
                  </div>
                </Card>
              </Column>
              <Column>
                <Card className="workshopCards bestDeal">
                  <div class="part-time-box">
                    <div class="top-icon-card">
                      <FontAwesomeIcon
                        icon={faUniversalAccess}
                        className="faIconColors cardIcon fa-2x"
                        alt="Icon pointer right"
                      />
                    </div>
                    <Title tag="h2" className="card-title is-3">
                      {shop.secondWorkshopTitle}
                    </Title>
                    <hr />
                    <div class="best-value">
                      <span class="best-value-price">
                        {shop.secondWorkshopFullPrice}
                      </span>
                      <p class="best-value-sub-line">
                        {shop.secondWorkshopNowSum}
                      </p>
                      <hr />
                      <BlockContent
                        blocks={shop._rawSecondWorkshopDepositExplenation}
                      />
                    </div>

                    <div class="small-list">
                      <BlockContent
                        blocks={shop._rawSecondWorkshopTimes}
                        serializers={serializers}
                      />
                      <span className="dateAndTime">
                        <BlockContent blocks={shop._rawSecondWorkshopTimes} />
                      </span>
                    </div>
                  </div>
                </Card>
              </Column>
              <Column>
                <Card className="workshopCards">
                  <div class="part-time-box">
                    <div class="top-icon-card">
                      <FontAwesomeIcon
                        icon={faHandHoldingSeedling}
                        className="cardIcon faIconColors fa-2x"
                        alt="Icon pointer right"
                      />
                    </div>
                    <Title tag="h2" className="card-title is-3">
                      {shop.thirdWorkshopTitle}
                    </Title>
                    <hr />
                    <div class="best-value">
                      <span class="best-value-price">
                        {" "}
                        {shop.thirdWorkshopFullPrice}
                      </span>{" "}
                      <p class="best-value-sub-line">
                        {shop.thirdWorkshopNowSum}
                      </p>
                      <hr />
                      <BlockContent
                        blocks={shop._rawThirdWorkshopDepositExplenation}
                      />
                    </div>

                    <div class="small-list">
                      <BlockContent
                        blocks={shop._rawThirdWorkshopTimes}
                        serializers={serializers}
                      />
                      <BlockContent blocks={shop._rawFirstWorkshopTimes} />
                    </div>
                  </div>
                </Card>
              </Column>
            </Columns>
          </Container>
          <hr />
        </Section>
      </Layout>
    </main>
  )
}
export const query = graphql`
  {
    sanityWorkshops {
      _rawFirstWorkshopDepositExplenation

      _rawIntroText
      _rawSecondWorkshopDepositExplenation
      _rawThirdWorkshopDepositExplenation
      _rawSecondWorkshopTimes
      _rawThirdWorkshopTimes
      secondWorkshopDates
      secondWorkshopFullPrice
      secondWorkshopNowSum
      secondWorkshopTitle
      thirdWorkshopDates
      thirdWorkshopFullPrice
      thirdWorkshopTitle
      title
      firstWorkshopTitle
      firstWorkshopNowSum
      firstWorkshopDates
      _rawFirstWorkshopTimes
      currency
      firstWorkshopFullPrice
      thirdWorkshopNowSum
    }
  }
`
export default Workshops
